import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorService} from '../common/error.service';
import {AuthenticationService} from '../../authentication/authentication.service';


interface ResponseAdSpace {
  status: string;
  error: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class AdSpaceService {

  adspaceConfigs: any;

  headers: any;

  getAdSpacesUrl = environment.api_url + '/ad-spaces';
  getAdSpaceConfigsUrl = environment.api_url + '/ad-space-configs';

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private authService: AuthenticationService
  ) {
    const accessToken = this.authService.getAccessTokenFromLocal();
    this.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken,
    });
  }

  getAdSpaceConfigs(): Observable<any> {
    if (!this.adspaceConfigs) {
      console.log('getAdSpaceConfigs');
      const headers = this.headers;
      this.adspaceConfigs = this.http.get<any>(this.getAdSpaceConfigsUrl, {headers})
        .pipe(catchError(this.errorService.handleError('getAdSpaceConfigs', {
          'data': {}
        })));
    }
    return this.adspaceConfigs;
  }

  // createAdSpace(data, owner_id): Observable<ResponseAdSpace> {
  //   const reqHeader = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + localStorage.getItem('access_token')
  //   });
  //   return this.http.post<ResponseAdSpace>(environment.api_url + '/ad-spaces?owner_id=' + owner_id, data, {headers: reqHeader});
  // }

  createAdSpace(adspace: any) {
    const headers = this.headers;
    return this.http.post<any>(this.getAdSpacesUrl, adspace, {headers}).pipe(catchError(this.errorService.handleError('createAdSpace', {
      'status': 'fail',
      'data': {}
    })));
  }

  getCampaignConfig(): Observable<ResponseAdSpace> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    return this.http.get<ResponseAdSpace>(environment.api_url + '/campaign-configs', {headers: reqHeader});
  }

  getAdSpacesWithAll(): Observable<any> {
    console.log('getAdSpacesWithAll');

    const headers = this.headers;

    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(this.getAdSpacesUrl + '/all', {headers}).pipe(catchError(this.errorService.handleError('getAdSpacesWithAll', {
      'data': {
        'adSpaces': [],
      }
    })));
  }
}
